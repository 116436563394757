<template>
  <div class="page my-page-wrap">
    <div class="inner">
      <div class="content">
        <div class="left">
          <img src="~@/assets/imgs/left.png" alt="" />
        </div>
        <div class="center main-content">
          <div class="tit_inner">
            <p class="entit">contact us</p>
            <p class="tit">联系我们</p>
            <p class="note">上午：8:00-12:00 下午：14:00-18:00</p>
          </div>

          <div class="contact_info">
            <!-- <div class="card">
              <div class="tit">企业管理部</div>
              <p class="text">联系人：<span>黄先生</span></p>
              <p class="text">联系电话：<span>4008-345-876 13768806188</span></p>
              <p class="text">地址：<span>广西南宁市兴宁区金川路9号1B01号（燕台山洞藏酒体验馆）</span></p>
            </div>
            <div class="card">
              <div class="tit">招商加盟部</div>
              <p class="text">联系人：<span>岑先生</span></p>
              <p class="text">联系电话：<span>4008-345-876 15994329382</span></p>
              <p class="text">地址：<span>广西南宁市兴宁区金川路9号1B01号（燕台山洞藏酒体验馆）</span></p>
            </div>
            <div class="card">
              <div class="tit">客户服务部</div>
              <p class="text">联系人：<span>韦先生</span></p>
              <p class="text">联系电话：<span>4008-345-876 18076367007</span></p>
              <p class="text">地址：<span>广西南宁市兴宁区金川路9号1B01号（燕台山洞藏酒体验馆）</span></p>
            </div> -->
            <div class="card"
              v-for="(card, index) in cards"   
              :key="index" >
              <div class="tit">{{ card.org }}</div>
              <p class="text">联系人：<span>{{ card.name }}</span></p>
              <p class="text">联系电话：<span>{{ card.tel }}</span></p>
              <p class="text">地址：<span>{{ card.address }}</span></p>
            </div> 
          </div>

          <!-- <div class="phone">
            <img src="~@/assets/imgs/kefu.png" alt="" />
          </div> -->

          <div class="map">
            <div id="map" style="height: 300px" ref="map"></div>
          </div>

            <!-- 销售网点 -->
            <div class="node">
            <div class="join_tit">
              <a @click="$router.push('/net')">更多网点>>></a>
            </div>
            <NodeList2 />
          </div>
        </div>
        <div class="right">
          <img src="~@/assets/imgs/right.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import NodeList2 from "./components/NodeList2.vue"
  import http from "@/utils/http"

  export default {
    data() {
      return {
        cards: []
      }
    },
    created() {   
      this.fetchCards();  
    },
    components: {
      NodeList2
    },

    mounted() {
      this.tabClick({ id: 13 })
      // 加载百度地图api
      this.initMap()
    },

    methods: {
      initMap() {
        var map = new BMapGL.Map("map")
        map.centerAndZoom(new BMapGL.Point(107.943089, 23.633397), 10)
        map.enableScrollWheelZoom(true)
        // 创建点标记
        var marker1 = new BMapGL.Marker(new BMapGL.Point(107.943089, 23.633397))
        // 在地图上添加点标记
        map.addOverlay(marker1)
        // }
      },

      tabClick(item) {
        this.activeId = item.id
        this.getDetail(item.id)
      },

      getDetail(id) {
        http({
          url: "/api/article/info?article_id=" + id,
          data: {}
        }).then((res) => {
          this.content = res.data.article_content
        })
      },
      async fetchCards() {
        http({
          url: "/api/contact/page",
          data: { page: 1, page_size: 50}
        }).then((res) => {
          this.cards = res.data.list;
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page {
    .tit_inner {
      position: relative;
      text-align: center;
      border-bottom: 1px solid #eee;
      margin-bottom: 40px;
      padding-bottom: 30px;
      .entit {
        display: block;
        position: absolute;
        z-index: 9;
        color: #ebebeb;
        font-size: 36px;
        text-transform: uppercase;
        width: 100%;
        top: 0;
        left: 0;
      }
      .tit {
        font-size: 42px;
        color: #000;
        display: block;
        position: relative;
        z-index: 999;
        width: 100%;
        top: 8px;
        left: 0;
        font-weight: lighter;
      }
      .note {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
      }
   
    }
    .contact_info {
      display: flex;
      flex-direction: row;
      justify-content: center;
      .card {
        border-radius: 10px;
        padding: 30px;
        text-align: center;
        line-height: 2;
        color: #444;
        
        box-shadow: 0 2px 20px 2px rgba(0, 0, 0, 0.05);
       
        .text {
          font-size: 16px;
          span {
            color: rgb(34, 34, 34);
            font-family: consolas, "lucida console", "courier new", monospace;
            white-space: pre-wrap;
           
            background-color: rgb(255, 255, 255);
          }
        }
        .tit {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
    .map {
      margin-top: 40px;
    }
    .node {
      padding-bottom: 50px;
    }
    .join_tit{
    margin-top: 47px;
    width: 100%;
    height: 31.5px;
    background: url(../../assets/imgs/net_tit.png)  no-repeat left center;
    background-size: 153px 31.5px;
    margin-bottom:15px;
  }
  .join_tit a{
    float:right;
    color:#b50003;
    cursor: pointer;
    font-weight: bold;
  }
  .join_tit a:hover{
    font-size: large;
  }
  }

</style>
